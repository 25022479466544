





























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class InsFooterLayout1 extends Vue {
    private Title:string='';
    private content:string='';
    private bg:string='';
    // 获取底部cms内容
    getContent () {
      this.$Api.cms.getContentByDevice({ ContentId: 20303, IsMobile: false }).then(result => {
        this.Title = result.CMS.Title;
        this.content = result.CMS.Body;
        this.bg = result.CMS.Cover;
      });
    }
    mounted () {
      this.getContent();
    }
}
